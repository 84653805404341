import { useEffect, useState } from 'react';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, RangeInput, Configure, useInstantSearch } from 'react-instantsearch';
import { getAllBriefApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';
import TableList from '../TableList/TableList';
import './globalSearch.css';
import TableListHeader from '../TableList/TableListHeader';

export default function TypesenseProjectListComponent({ searchClient, headers, columnWidth, hqUsers, showSearchOptions, showStatusOptions, showPropDueDateOptions, showBriefOwnerOptions, blockTitle, tablePaddingRequired }) {
  const [showFilterForBriefOwner, setShowFilterForBriefOwner] = useState(false);
  const toggleBriefOwner = () => { setShowFilterForBriefOwner(!showFilterForBriefOwner); };
  const [showFilterForBriefStatus, setShowFilterForBriefStatus] = useState(false);
  const toggleBriefStatus = () => { setShowFilterForBriefStatus(!showFilterForBriefStatus); };
  const [showFilterForCompanyCountry, setShowFilterForCompanyCountry] = useState(false);
  const toggleCompanyCountry = () => { setShowFilterForCompanyCountry(!showFilterForCompanyCountry); };

  const [searchWindowClassName, setSearchWindowClassName] = useState('search-box-visible');

  const LoadingIndicator = () => {
    const { status } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? <EditProfileLoader /> : null;
  };

  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
    if (error) {
      setSearchWindowClassName('search-window-hidden');
      return (
        <div>
          <span className='d-flex align-items-center'><h5 className='m-1'>Note: </h5><p className='m-1'> Use Control/Command + F to search for a brief</p></span>
          {
            briefsFromApi?.map((brief, i) => {
              return <div>No collection available. Please check with product team</div>;
            })
          }
        </div>);
    }

    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };

  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div>
        <p className='no-results-container'>
          No results for <q>{indexUiState.query}</q>.
        </p>
      </div>
    );
  };

  return (
    <div className='global-search'>
      <InstantSearch
        indexName='projectsv2'
        searchClient={searchClient}
        routing={true}
      >
        <Configure hitsPerPage={15} />
        {showSearchOptions && <main className={`${searchWindowClassName} search-box-header`}>
          <div className='search-box-container'>
            <SearchBox placeholder="Search" />
            <span className="search-box-options-text">Search by Name, Company or Keyword</span>
          </div>
          <div className="filter-sort-container search-box-filter">
            <div className="dropdown">
              <button
                className="btn btn_primary dropdown-toggle search-card-refinement-button"
                type="button"
                id="briefOwnerDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleBriefOwner}
              >
                    Owner
              </button>
              <div
                className={showFilterForBriefOwner
                  ? 'search-card-show-dropdown-items dropdown-menu'
                  : 'search-card-hide-dropdown-items'}
                aria-labelledby="briefOwnerDropdown"
              >
                <i className='bi bi-x-lg search-card-close-icon' onClick={toggleBriefOwner}></i>
                <RefinementList className="refine-list" attribute='owner.firstName' />
              </div>
            </div>

            <div className="dropdown">
              <button
                className="btn btn_primary dropdown-toggle search-card-refinement-button"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleCompanyCountry}
              >
                    Country
              </button>
              <div
                className={showFilterForCompanyCountry
                  ? 'search-card-show-dropdown-items dropdown-menu'
                  : 'search-card-hide-dropdown-items'}
                aria-labelledby="dropdownMenuButton"
              >
                <i className='bi bi-x-lg search-card-close-icon' onClick={toggleCompanyCountry}></i>
                <RefinementList className="refine-list" attribute='companyCountry.countryName' />
              </div>
            </div>

            <div className="dropdown">
              <button
                className="btn btn_primary dropdown-toggle search-card-refinement-button"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleBriefStatus}
              >
                    Status
              </button>
              <div
                className={showFilterForBriefStatus
                  ? 'search-card-show-dropdown-items dropdown-menu'
                  : 'search-card-hide-dropdown-items'}
                aria-labelledby="dropdownMenuButton"
              >
                <i className='bi bi-x-lg search-card-close-icon' onClick={toggleBriefStatus}></i>
                <RefinementList className="refine-list" attribute='status' />
              </div>
            </div>
          </div>
        </main>}
        {/* <NoResultsBoundary fallback={<NoResults />}> */}
        <div className='search-card-table-container' style={{padding: tablePaddingRequired ? '15px 55px 12px 70px' : ''}}>
          <div className='project-mashup-table-title-block'>{blockTitle}</div>
          <TableListHeader headers={headers} columnWidth={columnWidth}/>
          <div className="align-items-start search-container">
            <LoadingIndicator />
            <div className="col-12 search-card-main-window">

              <InfiniteHits className='row list-style' hitComponent={hit => <TableList hit={hit.hit} columnWidth={columnWidth} hqUsers={hqUsers} showStatusOptions={showStatusOptions} showPropDueDateOptions={showPropDueDateOptions} showBriefOwnerOptions={showBriefOwnerOptions}/>} />
            </div>
          </div>
        </div>
        {/* </NoResultsBoundary> */}
      </InstantSearch>
    </div>
  );


}