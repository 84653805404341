import { Link } from 'react-router-dom';
import { useState } from 'react';
import ShareBriefModal from '../Popups/ShareBriefModal';
import { Highlight} from 'react-instantsearch';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import CountryBadge from '../badges/CountryBadge';
import Tag from '../tags/Tag';
import ProfilePreview from '../../Pages/MasherProfileView';

export default function MashCard({ hit,currentid, setcurrentid }) {
  const {skills} = hit;
  const isNewMasher = moment(hit.createdAt) > new Date(new Date().setDate(- 30));
  const [openBriefModal, setOpenBriefModal] = useState(false);
  const handleModal = () => {
    setOpenBriefModal(!openBriefModal);
  };

  // const getRoleLabels = (roles) => {
  //   let roleLabelsArray = [];
  //   if (roles) {
  //     roles?.map(role => {
  //       roleLabelsArray.push(role.label);
  //     });
  //   } else {
  //     roleLabelsArray.push('No roles selected by Masher');
  //   }
  //   return roleLabelsArray.join(' / ');
  // };
  const getRoleLabels = (roles) => {
    let roleLabelsSet = new Set();
    if (roles) {
      roles.forEach(role => {
        roleLabelsSet.add(role.label);
      });
    } else {
      roleLabelsSet.add('No roles selected by Masher');
    }
    return Array.from(roleLabelsSet).join(' / ');
  };

  return (
    <div className='search-card-list-items'>
      <div className="search-card-user-container">
        <div className="search-card-user-header">
          <button 
            data-bs-toggle="modal"
            data-bs-target="#profilePreview"
            id="profilePreview_open"
            onClick={()=>{setcurrentid(hit._id);}}
          >
            <div className="search-card-image-container">
              <p
                style={{backgroundImage: `url(${hit.userId?.profilePic?.split(' ').join('%20') || hit.profilePic?.split(' ').join('%20') || ProfileDefault})`, backgroundSize: 'cover'}}
                className="search-card-image"
              ></p>
            </div>
          </button>
        </div>
        <div className="search-card-masher-details">
          <div className="search-card-masher-line-one">
            <span>
              <button 
                data-bs-toggle="modal"
                data-bs-target="#profilePreview"
                id="profilePreview_open"
                onClick={()=>{setcurrentid(hit._id);}}
                className='search-card-masher-name'
              >{hit.userId?.firstName ? 
                  `${hit.userId.firstName} ${hit.userId.lastName}` : 
                  <>
                    <Highlight classNames={{ highlighted: 'search-highlighted' }} attribute="firstName" style={{marginRight: '5px'}} hit={hit} /> 
                    <Highlight classNames={{ highlighted: 'search-highlighted' }} attribute="lastName" hit={hit} />
                  </>} 
              </button>
            </span>
            {isNewMasher && <span className="search-card-new-masher">NEW</span>}
          </div>
          { <div className='search-card-masher-roles'>{getRoleLabels(hit.roles)}</div>}
          <div className="search-card-masher-line-two">
            { hit.country &&<CountryBadge backgroundColor={'#F0F9FF'} title={hit.country}/>}
          </div>
        </div>
        <div className='search-card-masher-footer'>
          {!skills ?
            ''
            : hit.skills?.map((skill, i) => {
              return (
                <Tag title={skill.label} key={i}/>
              );
            })
          }
        </div>
      </div>

      <div className="search-card-right" >
        {openBriefModal && <ShareBriefModal masher={hit} handleModal={handleModal} />}
      </div>
    </div>
  );
}