import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import './tableList.css';
import Moment from 'react-moment';

function TableList({hit, columnWidth, hqUsers, showStatusOptions, showPropDueDateOptions, showBriefOwnerOptions}) {

  const joinName = (owner) => {
    let fullName = `${owner[0].firstName} ${owner[0].lastName}`;
    return fullName;
  };
  const getUserAvatar = (userId) => {
    const avatarUrl = hqUsers?.hits?.find(hqUser => {
      return hqUser?.document?._id === userId;
    });
    return avatarUrl?.document?.profilePic;
  };

  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item' >
          
          <div style={{width: columnWidth}} className='table-list-font-light'>
            {hit.refBriefId ? <Link to={`/projects/project-view/${hit._id}`}>{hit?.name} </Link> : <Link to={`/briefs/edit-brief/${hit._id}`}>{hit?.name} </Link>}
          </div>
          <div style={{width: columnWidth}} className='table-list-font-light'>{hit.companyName}</div>
          <div style={{width: columnWidth}} className='table-list-font-bold'>{hit.companyCurrency} {hit.feeAllocation[0].totalCost}</div>
          {showBriefOwnerOptions && <div style={{width: columnWidth, display: 'flex', alignItems: 'center'}} className='table-list-font-bold'><Avatar name={joinName(hit.owner)} url={getUserAvatar(hit.owner[0]?.userId)}/><span style={{marginLeft: '6px'}}>{joinName(hit.owner)}</span></div>}
          {showStatusOptions && <div style={{width: columnWidth}}><span className='green-badge'>{hit?.status || 'Pending'}</span></div>}
          <div style={{width: columnWidth}} className='table-list-font-light'><Moment format='DD/MM/YYYY' date={hit?.projectExpectedStartDate || hit?.createdAt } /></div>
          {showPropDueDateOptions && <div style={{width: columnWidth}} className='table-list-font-light'><Moment format='DD/MM/YYYY' date={hit?.projectExpectedEndDate || hit?.briefProposalDueDate} /></div>}
        </div>
      </div>
    </div>
  );
}

export default TableList;