import twemoji from 'twemoji';

/**
 * Helper component to make emojis work across OS/devices
 * @constructor
 * @param {string} codePoint - this gets converted to a img tag
 */
const Emoji = ({codePoint, className}) => {
  let unparsed = `<span>${codePoint}</span>`;
  
  const parsed = twemoji.parse(unparsed);
  
  return (<span className={className} dangerouslySetInnerHTML={{__html: parsed}}></span>);
};

export default Emoji;