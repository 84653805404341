import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../appContext';
import userIcon from '../../Icons/userIcon.svg';
import mashIcon from '../../Icons/mashLogoSidebarIcon.svg';
import briefIcon from '../../Icons/briefCaseIcon.svg';
import masherIcon from '../../Icons/mashersPeopleIcon.svg';
import projectsIcon from '../../Icons/projectsIcon.svg';
import customersIcon from '../../Icons/customersIcon.svg';

import cogIcon from '../../Icons/settingsCogIcon.svg';
import editIcon from '../../Icons/editIcon.svg';
import logoutIcon from '../../Icons/logoutIcon.svg';
import infoManual from '../../Icons/infoManual.svg';
import { assignBucketUrl } from '../../utils/helperFunctions';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import './sidebar.css';
import ClickUpHelpButton from '../ClickUpHelpButton';

export default function Sidebar() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { state, logout } = appContext;
  const { loggedInUser, isLoggedIn } = state;
  const handleLogout = () => {
    logout(navigate);
  };
  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
  return (
    <> 
      <section className="sidebar-container"> 
        <div className='sidebar-top-section'>
          <ul className='sidebar-ul'>
            <li className='sidebar-li'>
              <Link to='/'>
                <img src={assignBucketUrl(mashIcon)} alt='avatar'/>
              </Link>
            </li>  
            <li className='sidebar-li'>
              <Link to='/briefs'>
                <img src={briefIcon} alt="briefs button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <Link to='/projects'>
                <img src={projectsIcon} alt="projects button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <Link to='/mashers'>
                <img src={masherIcon} alt="mashers button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <Link to='/brands'>
                <img src={customersIcon} alt="customers button"/>
              </Link>
            </li>
          </ul>
        </div>

        <div className='sidebar-bottom-section'> 
          <ul className='sidebar-ul'>
            <li className='sidebar-li'>
              <div>
                <img
                  alt='user icon'
                  src={userIcon}
                  onClick={(() => setProfileDrawerOpen(!profileDrawerOpen))}
                  onError={(event) => {
                    event.target.src = ProfileDefault;
                    event.onerror = null;
                  }}
                />
              </div>
            </li>
            {/* 'Settings' link is for masher settings for now */}
            <li className='sidebar-li'>
              <Link to='https://docs.thisismash.com/shelves/hq-product-walkthrough' target="_blank">
                <img src={infoManual} alt="User manual button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <ClickUpHelpButton url="https://forms.clickup.com/9003215177/f/8ca4aa9-1242/9S2W1EHK23102MTQ6V" open={false} />
            </li>
          </ul>
        </div>

        {/* profile drawer */}
        {
          profileDrawerOpen &&
        <div className='sidebar-profile-drawer !fixed !bottom-16 !top-auto'>
          <ul className='sidebar-profile-drawer-ul'>
            <li className='sidebar-profile-drawer-li'>
              <div className='sidebar-profile-drawer-icon'>
                <img
                  alt='...'
                  src={assignBucketUrl(loggedInUser?.data?.[0]?.profilePic)}
                  onError={(event) => {
                    event.target.src = ProfileDefault;
                    event.onerror = null;
                  }}
                  id='sidebar-profile-drawer-avatar'
                />
              </div>
              <p className='sidebar-profile-drawer-title'>{`${loggedInUser?.data[0]?.firstName} ${loggedInUser?.data[0]?.lastName}`}</p>
            </li>
            <li className='sidebar-profile-drawer-li'>
              <img id='sidebar-profile-drawer-icon' src={editIcon} alt="edit profile button" className='sidebar-profile-drawer-icon'/>
              <Link to='/edit-profile' className='sidebar-profile-drawer-title '>
                <p id='sidebar-profile-drawer-link'>Edit Profile</p>
              </Link>             
            </li>
            <li className='sidebar-profile-drawer-li'>
              <img id='sidebar-profile-drawer-icon' src={logoutIcon} alt="edit profile button" className='sidebar-profile-drawer-icon'/>
              <p className='sidebar-profile-drawer-title' id='sidebar-profile-drawer-link' onClick={handleLogout}>Sign out</p>        
            </li>
          </ul>
        </div>
        }
      </section>
    </>
  );
}
