import axios from 'axios';

export const getHqUsersFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/hqusers/documents/search?q=*&query_by=&max_hits=all&per_page=250',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getBriefsFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/briefsv2/documents/search?q=*&query_by=&max_hits=all&per_page=250',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getBriefsWithoutProjectsFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/briefsv2/documents/search?q=*&query_by=&max_hits=all&per_page=250&filter_by=status:[qualified,proposal requested,proposal submitted]',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getProjectsFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/projectsv2/documents/search?q=*&query_by=',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getMashersFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/masherWithUser/documents/search?q=*&query_by=',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getMasherFromTypesense = async (id) => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: `/collections/masherWithUser/documents/search?q=${id}&query_by=_id`,
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getCompaniesFromTypesense = async (id, queryBy) => {
  return await axios({
    method: 'get',
    baseURL: `https://${process.env.REACT_APP_HOST_NAME}`,
    url: `/collections/companies/documents/search?q=${id || '*'}&query_by=${queryBy || ''}&max_hits=all`,
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
