import React, { createContext, useContext,useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import InviteSharePopup from './InviteMasherPopup';
import GetReferralPopup from '../../components/Popups/GetReferralPopup';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import AddButton from '../../components/common/buttons/AddButton';
import plusIconCircle from '../../Icons/plusIconCircle.svg';
import { AppContext } from '../../appContext';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseMasherListComponent from '../../components/TypesenseList/TypesenseMasherListComponent';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import ProfilePreview from '../MasherProfileView';
export const MasherContext = createContext();

export default function Masher() {
  const { loggedInUser } = useContext(AppContext);
  const userId = loggedInUser?.data[0]?._id;

  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'masherWithUser',
          query_by: 'firstName, lastName, country, skills.label, clientsWorkedFor, roles.value, roles.label',
          sort_by: 'createdAt:desc',
          facet_by: 'status',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const[currentid,setcurrentid]=useState('');
  const searchClient = typesenseInitiate();

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  
  return (
    <AdminLayout>
      <MasherContext.Provider value={{}}>
        <PageHeader title='Mashers' buttonsArray={[
        <button className={`simple-button primary-unfilled`}  data-bs-toggle="modal"   data-bs-target="#ReferralPopup" >
         Get Referrals
        </button>
          ,
          <button
            className='simple-button primary'
            role="link"
            onClick={() => openInNewTab('https://ats.thisismash.com/jobs/apply/masher-referral-4')}
          >
            <img className='simple-button-icon' src={plusIconCircle}/>Invite Masher
          </button>

        ]}/>
        <div>
          <article>
            <main>
              <TypesenseMasherListComponent
                  searchClient={searchClient}
                  currentid={currentid}
                  setcurrentid={setcurrentid}
              /> 
            </main>
          </article>
        </div>

        {/* Invite Modal */}
        <ProfilePreview id={currentid} />
        <InviteSharePopup userId={userId} />
        <GetReferralPopup />
      </MasherContext.Provider>
    </AdminLayout>
  );
}